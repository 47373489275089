<template>
  <BaseModal
    name="Modal-Automacao"
    id="Modal-Automacao"
    idModal="Modal-Automacao"
    size="lg"
    title="Automação de vendedores"
    @shown="openModal"
    @shown.once="openModal2"
    >
    <b-tabs
      nav-class="TabHeaderAutomation"
      active-nav-item-class="TabHeader--active"
      pills justified small
    >
      <div class="loading-all" v-if="(opt.id == 2 && loadingEqual) || (opt.id == 3 && loadingPerc) ||(opt.id == 1 && loadingAuto)">
        <b-spinner label="Loading..."></b-spinner>
      </div>
      <b-tab :title="`Distribuição igualitária`" @click="opt = { id: 2, name: 'Distribuição de leads' }; block = true">
        <div class="explain-card">Distribui os leads de forma igualitária entre os vendedores selecionados.</div>
        <div class="d-flex align-items-center">
          <b-form-checkbox
            @change="mudaStatus('equal')"
            v-model="equal_distribution_opt"
            name="is_main"
            size="lg"
            switch
            class="w-100"
          >
            <p class="info-checkbox mt-2">
              Ativar distribuição de leads de forma igualitária
            </p>
          </b-form-checkbox>
        </div>
        <!-- Pré vendedores -->
        <div class="sub-card mt-3" :style="equal_distribution_opt ? 'transition: all 0.3s;' : 'filter: saturate(0) contrast(0.9); transition: all 0.3s; position: relative;'" @click.stop="block = false">
          <div class="add-link-body mt-1">
            <label>Pré-vendedores</label>
            <router-link
              v-if="sellers.length === 1"
              :to="{ name: 'Usuarios' }"
              class="add-link"
              >Deseja adicionar vendedores?</router-link
            >
          </div>
          <BaseSelect
            v-if="opt.id == 2 && !loadingEqual"
            :selectModel="equalDistributionPreSellers"
            trackname="email"
            track-by="id"
            class="fit-height"
            :array="filteredEqualDistPreSellers"
            placeholder="Pesquise pelo e-mail"
            specificType="sellers"
            :multiple="true"
            :searchable="true"
            :loading="loading"
            :internal-search="true"
            :clear-on-select="false"
            :close-on-select="true"
            :show-no-results="true"
            :hide-selected="true"
            selectLabel=""
            deselectLabel=""
            selectedLabel=""
            @change="equalDistributionPreSellers = $event"
            @callback="getVendedor($event)"
            :allow-empty="true"
            ><span slot="noResult">Nenhum vendedor encontrado.</span>
          </BaseSelect>
          <input type="text" class="w-100" disabled v-else style="margin-bottom: 0 !important;">
          <div class="d-flex align-items-center">
            <b-form-checkbox
              v-model="changePreSellerDistribution"
              name="is_main"
              size="lg"
              switch
              class="w-100"
            >
              <p class="info-checkbox mt-2">
                Trocar os pré-vendedores ao se cadastrar novamente
              </p>
            </b-form-checkbox>
          </div>
          <div :style="equal_distribution_opt ? 'display: none' : 'position: absolute; z-index: 10; width: 100%; height: 100%; top: 0;'"></div>
        </div>
        <!-- Vendedor -->
        <div class="sub-card mt-3" :style="equal_distribution_opt ? 'transition: all 0.3s;' : 'filter: saturate(0) contrast(0.9); transition: all 0.3s; position: relative;'" @click.stop="block = false">
          <div class="add-link-body mt-1">
            <label>Vendedores</label>
            <router-link
              v-if="sellers.length === 1"
              :to="{ name: 'Usuarios' }"
              class="add-link"
              >Deseja adicionar vendedores?</router-link
            >
          </div>
          <BaseSelect
            class="fit-height"
            v-if="opt.id == 2 && !loadingEqual"
            :selectModel="equal_distribution_sellers"
            trackname="email"
            track-by="id"
            :array="filteredEqualDistSellers"
            placeholder="Pesquise pelo e-mail"
            specificType="sellers"
            :multiple="true"
            :searchable="true"
            :loading="loading"
            :internal-search="true"
            :clear-on-select="false"
            :close-on-select="true"
            :show-no-results="true"
            :hide-selected="true"
            selectLabel=""
            deselectLabel=""
            selectedLabel=""
            @change="equal_distribution_sellers = $event"
            @callback="getVendedor($event)"
            :allow-empty="true"
            ><span slot="noResult">Nenhum vendedor encontrado.</span>
          </BaseSelect>
          <input type="text" class="w-100" disabled v-else style="margin-bottom: 0 !important;">
          <div class="d-flex align-items-center">
            <b-form-checkbox
              v-model="change_seller_distribution"
              name="is_main"
              size="lg"
              switch
              class="w-100"
            >
              <p class="info-checkbox mt-2">
                Trocar o vendedor ao se cadastrar novamente
              </p>
            </b-form-checkbox>
          </div>
          <div :style="equal_distribution_opt ? 'display: none' : 'position: absolute; z-index: 10; width: 100%; height: 100%; top: 0;'"></div>
        </div>
      </b-tab>
      <b-tab :title="`Distribuição por porcentagem`" @click="opt = { id: 3, name: 'Distribuição de leads por porcentagem' }; block = true">
        <div class="explain-card">Distribui os leads por meio de porcentagem.</div>
        <div class="d-flex align-items-center">
          <b-form-checkbox
            @change="mudaStatus('perc')"
            v-model="percentage_distribution_opt"
            name="is_main"
            size="lg"
            switch
            class="w-100"
          >
            <p class="info-checkbox mt-2">
              Ativar distribuição de leads por meio de porcentagem
            </p>
          </b-form-checkbox>
        </div>
        <div class="sub-card mt-2" :style="percentage_distribution_opt ? 'transition: all 0.3s;' : 'filter: saturate(0) contrast(0.9); transition: all 0.3s; position: relative;'" @click.stop="block = false">
          <div class="add-link-body">
            <label>Vendedores</label>
            <router-link
              v-if="sellers.length === 1"
              :to="{ name: 'Usuarios' }"
              class="add-link"
              >Deseja adicionar vendedores?</router-link
            >
          </div>
          <BaseSelect
            class="fit-height"
            v-if="opt.id == 3"
            :selectModel="sellersSelected"
            trackname="email"
            track-by="id"
            :array="sellers"
            placeholder="Pesquise pelo e-mail"
            specificType="sellers"
            :multiple="true"
            :searchable="true"
            :loading="loading"
            :internal-search="true"
            :clear-on-select="false"
            :close-on-select="true"
            :show-no-results="true"
            :hide-selected="true"
            selectLabel=""
            deselectLabel=""
            selectedLabel=""
            @change="sellersSelected = $event, retornaPorcentagemTotal(), retornaSemVendedor()"
            @callback="getVendedor($event)"
            :allow-empty="true"
            ><span slot="noResult">Nenhum vendedor encontrado.</span>
          </BaseSelect>
          <div class="sub-card mt-3">
            <div style="display: grid; grid-template-columns: 5fr 2fr 1fr;">
              <label>Vendedor</label>
              <label>Porcentagem</label>
              <label>Remover</label>
            </div>
            <div class="equal-seller">
              <div>
                <p class="name">{{ sem_vendedor.display_name }}</p>
              </div>
              <div
                v-b-tooltip.hover.top="
                  'A porcentagem do sem vendedor irá se atualizar caso não distribuir 100%'
                "
                ><input
                  disabled
                  v-model="sem_vendedor.percentage"
                  class="w-100"
                  type="number"
                  name="percentage"
                  id="percentage"
              /></div>
  
            </div>
            <div
              v-for="(item, index) in sellersSelected"
              :key="index"
              class="equal-seller"
            >
              <div>
                <p class="name">{{ item.first_name }}</p>
                <p class="email">{{ item.email }}</p>
              </div>
              <input
                @input="validaPorcentagemTotal(`seller-${item.id}`, index)"
                :ref="'seller-' + item.id"
                v-model="item.percentage"
                class="w-100"
                type="number"
                name="percentage"
                id="percentage"
              />
              <img
                @click="removeSeller(index, item.percentage)"
                id="img-remove"
                src="@/assets/img/icons/x.svg"
              />
            </div>
          </div>
          <div :style="percentage_distribution_opt ? 'display: none' : 'position: absolute; z-index: 10; width: 100%; height: 100%; top: 0;'"></div>
        </div>
      </b-tab>
      <b-tab :title="`Redistribuição pós-cadastro`" @click="opt = { id: 1, name: 'Automação de vendedor' }; block = true">
        <div>
          <div class="explain-card">
            Redistribui leads que tiverem a tag de entrada e não tiverem a tag de saída nos horários definidos. Pode ser usado em conjunto com as demais distribuições.
          </div>
          <b-form-checkbox
            v-model="option"
            name="is_main"
            size="lg"
            switch
            class="w-100 mt-0"
          >
            <p class="info-checkbox mb-3">
              Ativar distribuição pós-cadastro
            </p>
          </b-form-checkbox>
        </div>
        <div class="sub-card" :style="option ? 'transition: all 0.3s; padding: 15px 15px 3px;' : 'filter: saturate(0) contrast(0.9); transition: all 0.3s; position: relative; padding: 15px 15px 3px;'" v-if="opt.id === 1">
          <div class="select-days mb-3">
            <div v-for="n in 7" :key="n" :class="dia_seletec.includes(dias_semana[n - 1]) ? 'selected-day' : ''" @click="dia_seletec.includes(dias_semana[n - 1]) ? dia_seletec.splice(dia_seletec.indexOf(dias_semana[n - 1]), 1) : dia_seletec.push(dias_semana[n - 1])">
              {{dias_semana[n - 1].dia}}
            </div>
          </div>
          <div class="time-range">
            <input v-model="time1" type="range" min="0" max="144">
            <input v-model="time2" type="range" min="0" max="144">
            <div class="input-detail" :style="dynamicRange">
              <div :label2="time2Label" :label1="time1Label"></div>
            </div>
          </div>
          <div style="display: grid; grid-template-columns: 1fr 1fr; gap: 0 15px;" @click.stop="block = false">
            <div class="add-link-body mt-2">
              <label class="label-filter">
                Tags de entrada
              </label>
              <router-link
                v-if="!tags.length && verificUserLevel"
                :to="{ name: 'Tags' }"
                class="add-link"
                >Deseja criar uma tag?</router-link
              >
            </div>
            <div class="add-link-body mt-2">
              <label class="label-filter">Tags de saída </label>
              <router-link
                v-if="!tags.length && verificUserLevel"
                :to="{ name: 'Tags' }"
                class="add-link"
                >Deseja criar uma tag?</router-link
              >
            </div>
            <BaseSelect
              class="fit-height"
              :selectModel="tags_selected"
              direction="top"
              trackname="name"
              specificType="tags"
              track-by="id"
              :array="tags"
              :multiple="true"
              :searchable="true"
              :clear-on-select="false"
              :taggable="true"
              :close-on-select="true"
              :show-no-results="true"
              :hide-selected="true"
              selectLabel=""
              deselectLabel=""
              selectedLabel=""
              @callback="fetchTagService($event)"
              @change="tags_selected = $event"
              :allow-empty="true"
            >
              <span slot="noResult"> Nenhuma tag encontrada.</span>
            </BaseSelect>
            <BaseSelect
              class="fit-height"
              :selectModel="tags_saida_selected"
              direction="top"
              trackname="name"
              specificType="tags"
              track-by="id"
              :array="tags"
              :multiple="true"
              :taggable="true"
              :searchable="true"
              :clear-on-select="false"
              :close-on-select="true"
              :show-no-results="true"
              :hide-selected="true"
              selectLabel=""
              deselectLabel=""
              selectedLabel=""
              @callback="fetchTagService($event)"
              @change="tags_saida_selected = $event"
              :allow-empty="true"
            >
              <span slot="noResult"> Nenhuma tag encontrada.</span>
            </BaseSelect>
            <div class="add-link-body mt-3">
              <label>Vendedores</label>
              <router-link
                v-if="sellers.length === 1"
                :to="{ name: 'Usuarios' }"
                class="add-link"
                >Deseja adicionar vendedores?</router-link
              >
            </div>
            <label class="titulo mt-3" style="font-size: 14px !important">
              Prazo para contato com o lead ({{ tempo }} {{ tempo == 1 ? 'hora' : 'horas' }})
            </label>
            <BaseSelect
              class="fit-height"
              v-if="opt.id == 1"
              direction="top"
              :selectModel="seller_selected"
              trackname="email"
              track-by="id"
              :array="sellers"
              placeholder="Pesquise pelo e-mail"
              specificType="sellers"
              :multiple="true"
              :searchable="true"
              :loading="loading"
              :internal-search="true"
              :clear-on-select="false"
              :close-on-select="true"
              :show-no-results="true"
              :hide-selected="true"
              selectLabel=""
              deselectLabel=""
              selectedLabel=""
              @change="seller_selected = $event"
              @callback="getVendedor($event)"
              :allow-empty="true"
              ><span slot="noResult">Nenhum vendedor encontrado.</span>
            </BaseSelect>
            <input
              type="range"
              min="1"
              max="12"
              v-model="tempo"
            />
          </div>
          <div :style="option ? 'display: none' : 'position: absolute; z-index: 10; width: 100%; height: 100%; top: 0;'"></div>
        </div>
      </b-tab>
      <b-tab :title="`Gestão por formulário`" @click="opt = { id: 4, name: 'Gestão por formulário' }; block = true">
        <div>
          <div class="explain-card">
            Selecione múltiplos formulários para atribuir vendedores, a mesma configuração pode ser vista dentro da edição do formulário. Essa distribuição sobrepõe as demais caso a opção "Sobrepor outras distribuições" estiver ativa.
          </div>
          <div class="sub-card mt-2" @click.stop="block = false">
            <div class="add-link-body">
              <label>Formulários</label>
            </div>
            <BaseSelect
              class="fit-height"
              :clear="clear"
              :selectModel="formulario"
              trackname="title"
              label=""
              track-by="id"
              :array="forms"
              :multiple="true"
              :searchable="true"
              :loading="loading"
              :internal-search="true"
              :clear-on-select="false"
              :close-on-select="true"
              :show-no-results="true"
              :hide-selected="true"
              selectLabel=""
              deselectLabel=""
              selectedLabel=""
              :currentPage="formCurrentPage"
              @callback="getForms($event)"
              @change="formulario = $event"
              :watch="true"
              ><span slot="noResult">Nenhum formulário encontrado.</span>
            </BaseSelect>

            <div class="add-link-body mt-2">
              <label>Pré-vendedores</label>
              <router-link
                v-if="sellers.length === 1"
                :to="{ name: 'Usuarios' }"
                class="add-link"
                >Deseja adicionar pré-vendedores?</router-link
              >
            </div>
            <BaseSelect
              class="fit-height"
              v-if="opt.id == 4"
              :selectModel="preSellersSelected2"
              trackname="email"
              track-by="id"
              :array="filteredFormPreSellers"
              placeholder="Pesquise pelo e-mail"
              specificType="sellers"
              :multiple="true"
              :searchable="true"
              :loading="loading"
              :internal-search="true"
              :clear-on-select="false"
              :close-on-select="true"
              :show-no-results="true"
              :hide-selected="true"
              selectLabel=""
              deselectLabel=""
              selectedLabel=""
              :currentPage="userCurrentPage"
              @change="preSellersSelected2 = $event"
              @callback="getVendedor($event)"
              :allow-empty="true"
              ><span slot="noResult">Nenhum pré-vendedor encontrado.</span>
            </BaseSelect>

            <div class="add-link-body mt-2">
              <label>Vendedores</label>
              <router-link
                v-if="sellers.length === 1"
                :to="{ name: 'Usuarios' }"
                class="add-link"
                >Deseja adicionar vendedores?</router-link
              >
            </div>
            <BaseSelect
              class="fit-height"
              v-if="opt.id == 4"
              :selectModel="sellersSelected2"
              trackname="email"
              track-by="id"
              :array="filteredFormDistSellers"
              placeholder="Pesquise pelo e-mail"
              specificType="sellers"
              :multiple="true"
              :searchable="true"
              :loading="loading"
              :internal-search="true"
              :clear-on-select="false"
              :close-on-select="true"
              :show-no-results="true"
              :hide-selected="true"
              selectLabel=""
              deselectLabel=""
              selectedLabel=""
              :currentPage="userCurrentPage"
              @change="sellersSelected2 = $event"
              @callback="getVendedor($event)"
              :allow-empty="true"
              ><span slot="noResult">Nenhum vendedor encontrado.</span>
            </BaseSelect>

            <div class="d-flex align-items-center">
              <b-form-checkbox
                v-model="ignoreOthersDistributions"
                name="is_main"
                size="lg"
                switch
                class="w-100"
              >
                <p class="info-checkbox mt-2">
                  Sobrepor outras distribuições
                </p>
              </b-form-checkbox>
            </div>
          </div>
        </div>
      </b-tab>
    </b-tabs>
    <template v-slot:footer="{ cancel }">
      <!-- AUTOMAÇÂO DE VENDEDOR -->
      <div v-if="opt.id != 4 ">
        <BaseButton variant="link-info" class="mr-4" @click="cancel">
          Fechar
        </BaseButton>
        <BaseButton variant="primary" :disabled="loading" @click="opt.id == 1 ? onSubmit() : onSubmitAll()">
          Salvar 
        </BaseButton>
      </div>
      <div v-else>
        <BaseButton variant="link-info" class="mr-4" @click="cancel">
          Cancelar
        </BaseButton>
        <BaseButton :disabled="loading" variant="primary" @click="onSubmitForm">
          Salvar 
        </BaseButton>
      </div>
    </template>
    <!-- <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div> -->
  </BaseModal>
</template>

<script>
// AUTOMAÇÂO DE VENDEDOR -->
import Multiselect from "../../../../node_modules/vue-multiselect";
import "../../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css";
import sellerAutomation from "@/services/resources/SellerAutomation";
const serviceSellerAutomation = sellerAutomation.build();
import SellerListService from "@/services/resources/SellerListService";
const serviceSeller = SellerListService.build();
import ListarTagService from "@/services/resources/ListarTagService";
const serviceListarTag = ListarTagService.build();

// DISTRIBUIÇÂO DE LEADS -->
import SettingsService from "@/services/resources/SettingsService";
const serviceSettings = SettingsService.build();
// DISTRIBUIÇÂO DE LEADS POR PORCENTAGEM -->
import Vue from "vue";

import DistribuicaoService from "@/services/resources/DistribuicaoService";
const serviceDistribuicao = DistribuicaoService.build();

import FormService from "@/services/resources/FormService";
const serviceForm = FormService.build();

import TagService from "@/services/resources/TagService";
const serviceTags = TagService.build();

export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      loadingEqual: true,
      loadingPerc: true,
      loadingAuto: true,
      time1: 48,
      time1Label: '08:00',
      time2: 108,
      time2Label: '18:00',
      formulario: [],
      forms: [],
      block: false,
      clear: false,
      render: true,
      stops: {
        seller: false,
        tag: false,
      },
      sellersPage: 1,
      tagsPage: 1,
      // MultiSelect
      options: [
        { id: 1, name: "Automação de vendedor" },
        { id: 2, name: "Distribuição de leads" },
        { id: 3, name: "Distribuição de leads por porcentagem" },
        { id: 4, name: "Gestão por formulário" },
      ],
      opt: { id: 0, name: "" },
      // DISTRIBUIÇÂO DE LEADS POR PORCENTAGEM -->
      sellersSelected: [],
      sellersSelected2: [],
      preSellersSelected2: [],
      sellers: [],
      percentage_distribution_opt: false,
      increment: null,
      sem_vendedor: {
        id: 0,
        display_name: "Sem vendedor",
        user_email: "Sem vendedor",
        percentage: 0,
      },
      // DISTRIBUICAO DE LEADS -->
      equal_distribution_opt: false,
      change_seller_distribution: false,
      changePreSellerDistribution:false,
      equal_distribution_sellers: [],
      equalDistributionPreSellers: [],
      // AUTOMAÇÂO DE VENDEDOR -->
      forms: this.$store.getters.forms,
      select_form: [],
      dias_semana: [
        { id: 7, dia: "Domingo" },
        { id: 1, dia: "Segunda" },
        { id: 2, dia: "Terça" },
        { id: 3, dia: "Quarta" },
        { id: 4, dia: "Quinta" },
        { id: 5, dia: "Sexta" },
        { id: 6, dia: "Sábado" },
      ],
      dia_seletec: [],
      final_dia_selected: [],
      tags: [],
      tags_selected: [],
      final_tag_selected: [],
      tags_saida_selected: [],
      final_tag_saida_selected: [],
      seller: [],
      seller_selected: [],
      final_seller_selected: [],
      tempo: 1,
      option: "",
      loading: false,

      loadingTags: false,
      searchTags: "",
      searchVendedor: "",
      stops: [],
      searchForm: "",
      stopForm: false,
      is_getting_forms: false,
      formCurrentPage: 0,
      is_getting_users: false,
      userCurrentPage: 0,
      ignoreOthersDistributions: false,
    };
  },
  watch: {
    dia_seletec(valor) {
      this.filterDiasSemanas(valor);
    },
    tags_selected(valor) {
      this.filterTags(valor);
    },
    tags_saida_selected(valor) {
      this.filterTagsSaida(valor);
    },
    seller_selected(valor) {
      this.filterSeller(valor);
    },
    time1(valor) {
      if (valor > parseInt(this.time2) - 4) {
        this.time1 = parseInt(this.time2) - 4
        return
      }
      const minutes = (valor % 6) * 10;
      const minutesStr = String(minutes).padStart(2, '0');
      this.time1Label = `${String(Math.floor(valor / 6)).padStart(2, '0')}:${minutesStr}`;
    },
    time2(valor) {
      if (valor < parseInt(this.time1) + 4) {
        this.time2 = parseInt(this.time1) + 4
        return
      }
      const minutes = (valor % 6) * 10;
      const minutesStr = String(minutes).padStart(2, '0');
      this.time2Label = `${String(Math.floor(valor / 6)).padStart(2, '0')}:${minutesStr}`;
    }
  },
  computed: {
    dynamicRange() {
      let perc = (this.time2 - this.time1) / 144
      let pxs = 46 * perc
      let left = this.time1 / 1.44
      let leftPxs = (left / 100) * 46
      return `width: calc(${perc * 100}% - ${pxs}px); left: calc(${left}% - ${leftPxs}px)`
    },
    vendedores() {
      return this.$store.getters.seller;
    },
    verificUserLevel() {
      var user = this.$store.getters.user.user.level;
      if (user === "owner" || user === "admin" || user == "gerent_seller") {
        return true;
      } else {
        return false;
      }
    },
    filteredEqualDistPreSellers() {
      const filteredEqualDistPresellers = this.sellers.filter(preSeller => 
        !this.equal_distribution_sellers.some(seller => seller.id === preSeller.id)
      );
      return filteredEqualDistPresellers;
    },
    filteredEqualDistSellers() {
      const filteredSellers = this.sellers.filter(seller => 
        !this.equalDistributionPreSellers.some(preSeller => preSeller.id === seller.id)
      );
      return filteredSellers;
    },
    filteredFormPreSellers() {
      const filteredFormPreSellers= this.sellers.filter(preSeller => 
        !this.sellersSelected2.some(seller => seller.id === preSeller.id)
      );
      return filteredFormPreSellers
    },
    filteredFormDistSellers() {
      const filteredFormSellers = this.sellers.filter(seller => 
        !this.preSellersSelected2.some(preSeller => preSeller.id === seller.id)
      );
      return filteredFormSellers;
    },
  },
  methods: {
    async onSubmitAll() {
      this.loading = true
      if (this.equal_distribution_opt) {
        let data = {
          meta_key: "equal_distribution_opt",
          meta_value: "true",
        };
        await serviceSettings.create(data);
        this.onSubmit2()
      } else {
        let data = {
          meta_key: "equal_distribution_opt",
          meta_value: "false",
        };
        await serviceSettings.create(data).then();;
      }
      if (this.percentage_distribution_opt){
        let data = {
          meta_key: "percentage_distribution_opt",
          meta_value: "true",
        };
        await serviceSettings.create(data)
        this.salvarSellers()
      } else {
        let data = {
          meta_key: "percentage_distribution_opt",
          meta_value: "false",
        };
        await serviceSettings.create(data).then();;
      }
      if(!this.percentage_distribution_opt && !this.equal_distribution_opt){
        this.$bvModal.hide("Modal-Automacao")
        this.$grToast.toast("Automação salva com sucesso", {
          title: "Distribuição de leads",
          variant: "info",
          autoHideDelay: 5000,
          appendToast: true,
        });
        this.loading = false
      }      
    },
    getForms(page = 1) {
      if (this.stops.includes("form") || this.is_getting_forms) {
        return;
      }

      this.is_getting_forms = true;

      var data = {
        id: `?status=active&page=${page}`,
      };

      serviceForm.read(data).then((resp) => {
        if (resp.data.length < resp.per_page) {
          this.stops.push("form");
        }
        this.formCurrentPage = resp.current_page;
        const selectedFormularioIds = this.formulario.map((item) => item.id);
        this.forms = this.forms.concat(resp.data);
        if(this.formulario.length == 0){
          this.formulario = this.forms.filter((item) =>
          selectedFormularioIds.includes(item.id)
          );
        }
      })
      .finally(() => {
        this.is_getting_forms = false;
      });
    },
    onSubmitForm(){
      this.loading = true
      if( this.formulario.length == 0){
        this.$grToast.toast("Selecione um formulário", {
              title: "Automação de vendedores",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
            this.loading = false
        return
      }
      if( this.sellersSelected2.length == 0){
        this.$grToast.toast("Selecione um vendedor", {
              title: "Automação de vendedores",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
            this.loading = false
        return
      }
      var data = {
        "id": '/metas/automation',
        "forms": this.formulario.length
          ? this.formulario.map(function (item) {
              return item.id + "";
            })
          : [],
        sellers: this.sellersSelected2.length
          ? this.sellersSelected2.map(function (item) {
              return item.id + "";
            })
          : [],
          preSellers: JSON.stringify(this.preSellersSelected2.length
          ? this.preSellersSelected2.map(function (item) {
              return item.id + "";
            })
          : []),
          ignore_others_distributions: this.ignoreOthersDistributions ? "true" : "false"
      };
      serviceForm.createId(data).then((resp) => {  
        this.loading = false
        this.$grToast.toast("Formulários salvos com sucesso", {
            title: "Automação de vendedores",
            variant: "info",
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.$bvModal.hide("Modal-Automacao")
      });
    },
    fetchTagService() {
      if (this.stops.tag || this.block) {
        return;
      }
      this.block = true
      let data = {
        page: this.tagsPage,
        per_page: 10,
      };

      serviceListarTag.search(data).then((response) => {
        this.tagsPage++
        if (response.data.length < response.per_page) {
          this.stops.tag = true;
        }
        this.tags = this.tags.concat(response.data);
        this.tags = this.tags.filter(
          (item, index, opt) => index === opt.findIndex((t) => t.id === item.id)
        );
        this.block = false
      });
    },

    // DISTRUIBUIÇÂO DE LEADS POR PORCENTAGEM-->
    async getVendedor() {
      if (this.stops.seller || this.block || this.is_getting_users) {
        return;
      }
      this.is_getting_users = true;
      this.block = true
      var data = {
        page: this.sellersPage,
      };
      await serviceSeller
        .search(data)
        .then((resp) => {
          this.sellersPage++
          if (resp.data.length < resp.per_page) {
            this.stops.seller = true;
          }
          this.userCurrentPage = resp.current_page;
          this.sellers = this.sellers.concat(resp.data);
          this.block = false
        })
        .catch((err) => {
        })
        .finally(() => {
          this.is_getting_users = false;
        })
    },
    fetchSellers() {
      // this.loading = true;
      serviceDistribuicao.read("").then((resp) => {
        var sellers = resp;
        for (let i = 0; i < sellers.length; i++) {
          const element = sellers[i];
          var result = element.user;
          if (result) {
            result.percentage = parseInt(element.user_percentage);
            this.sellersSelected.push(result);
          }
          if (element.user_id == 0) {
            this.sem_vendedor.percentage = parseInt(element.user_percentage);
          }
        }
        this.retornaPorcentagemTotal()
        this.retornaSemVendedor()
        this.loadingPerc = false;
      });
    },
    fetchOpts() {
      serviceSettings.read("/percentage-distribution").then((resp) => {
        if (resp.length > 0) {
          this.percentage_distribution_opt =
            resp[0].meta_value == "true" ? true : false;
        }
      })
    },
    retornaPorcentagemTotal() {
      var porcentagem_total = 0;
      for (let i = 0; i < this.sellersSelected.length; i++) {
        const element = this.sellersSelected[i].percentage;
        if (element !== "") {
          porcentagem_total += parseInt(element);
        }
        // else{
        //   porcentagem_total += 1;
        // }
      }

      return porcentagem_total;
    },
    validaPorcentagemTotal(vendedor, index) {
      if (
        this.$refs[vendedor][0].value < 1 &&
        this.$refs[vendedor][0].value !== ""
      ) {
        this.$grToast.toast("Porcentagem precisa ter ao menos 1 por cento", {
          title: "Distribuição de leads",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        Vue.set(this.sellersSelected[index], "percentage", 1);
        this.$refs[vendedor][0].value = 1;
      }

      if (this.retornaPorcentagemTotal() > 100) {
        var per = 0;
        for (let i = 0; i < this.sellersSelected.length; i++) {
          const element = this.sellersSelected[i].percentage;
          if (parseInt(element) < 100 && per + element <= 100) {
            per += parseInt(element);
          }
        }
        Vue.set(this.sellersSelected[index], "percentage", 100 - per);
        this.$refs[vendedor][0].value = 100 - per;

        this.$grToast.toast(
          "Porcentagem total maior que cem, ajuste a porcentagem dos vendedores",
          {
            title: "Distribuição de leads",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          }
        );
      }

      this.retornaSemVendedor();
    },
    mudaStatus(place) {
      if (place == 'perc') {
        if (this.percentage_distribution_opt) {
          this.equal_distribution_opt = !this.percentage_distribution_opt
        } 
      } else {
        if (this.equal_distribution_opt) {
          this.percentage_distribution_opt = !this.equal_distribution_opt
        }
      }
    },
    salvarSellers() {
      this.loading = true;
      let sellers = JSON.parse(JSON.stringify(this.sellersSelected))
      for (let i = 0; i < sellers.length; i++) {
        const element = sellers[i];
        sellers[i].percentage = Math.abs(element.percentage);
      }

      if (this.sem_vendedor.percentage > 0) {
        sellers.push({
          id: 0,
          tenant_id: this.$store.getters.user.user.tenant_id,
          percentage: this.sem_vendedor.percentage,
        });
      }

      let data = {
        id: "",
        users: sellers,
      };

      serviceDistribuicao
        .create(data)
        .then(() => {          
          this.$grToast.toast("Distribuição configurada com sucesso", {
            title: "Distribuição de leads",
            variant: "info",
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.$bvModal.hide("Modal-Automacao");
          this.loading = false
        })
        .catch(() => {
          this.loading = false
          this.$grToast.toast("Erro ao configurar distribuição", {
            title: "Distribuição de leads",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .finally(() => {
        });
    },
    removeSeller(index, percentage) {
      this.sellersSelected.splice(index, 1);
      this.retornaSemVendedor();
    },
    retornaSemVendedor() {
      var total = this.retornaPorcentagemTotal();

      if (total < 100) {
        var diff = 100 - parseInt(total);
        this.sem_vendedor.percentage = diff;
      } else {
        this.sem_vendedor.percentage = 0;
      }
    },
    removeSellerMultiselect() {
      this.retornaSemVendedor();
    },
    addSeller(e) {
      if (this.sem_vendedor.percentage == 0) {
        this.$grToast.toast(
          "Impossível adicionar vendedor, confira as porcentagens dos vendedores configurados",
          {
            title: "Distribuição de leads",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          }
        );
        this.sellersSelected.pop();
        return;
      }
      setTimeout(() => {
        e.percentage = 1;
      }, 100);
      this.sem_vendedor.percentage = this.sem_vendedor.percentage - 1;
    },
    // DISTRUIBUIÇÂO DE LEADS -->
    onSubmit2() {
      this.loading = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          var options = [
            "equal_distribution_opt",
            "equal_distribution_sellers",
            "equalDistributionPreSellers",
            "change_seller_distribution",
            "changePreSellerDistribution"
          ];

          for (let i = 0; i < options.length; i++) {
            const option = options[i];
            var data = {
              meta_key: option,
            };

            if (i === 0) {
              data.meta_value =
                this.equal_distribution_opt === true ? "true" : "false";
            } else if (i === 1) {
              data.meta_value = this.equal_distribution_sellers.length
                ? JSON.stringify(
                    this.equal_distribution_sellers.map(function (item) {
                      return item.id + "";
                    })
                  )
                : "[]";
            } else if (i === 2) {
              data.meta_value = this.equalDistributionPreSellers.length
                ? JSON.stringify(
                    this.equalDistributionPreSellers.map(function (item) {
                      return item.id + "";
                    })
                  )
                : "[]";
            } else if (i === 3) {
              data.meta_value =
                this.change_seller_distribution === true ? "true" : "false";
            }
            else if (i === 4) {
              data.meta_value =
                this.changePreSellerDistribution === true ? "true" : "false";
            }
            serviceSettings.create(data).then((response) => {              
              if (i === 2) {
                this.$bvModal.hide("Modal-Automacao");
                this.loading = false
                this.$grToast.toast("Automação salva com sucesso", {
                  title: "Distribuição de leads",
                  variant: "info",
                  autoHideDelay: 5000,
                  appendToast: true,
                });
              }
            });
          }
        } else {
          this.loading = false;
        }
      });
    },
    fetchOptions() {
      serviceSettings
        .read({ id: `equal-distribution` })
        .then((resp) => {
          for (let i = 0; i < resp.length; i++) {
            const element = resp[i];
            switch (element.meta_key) {
              case "equal_distribution_opt":
                this.equal_distribution_opt =
                  element.meta_value == "true" ? true : false;
                break;
              case "change_seller_distribution":
                this.change_seller_distribution =
                  element.meta_value == "true" ? true : false;
                break;
              case "changePreSellerDistribution":
                this.changePreSellerDistribution =
                  element.meta_value == "true" ? true : false;
                break;
              case "equal_distribution_sellers":
                this.equal_distribution_sellers = element.meta_value;
                break;
              case "equalDistributionPreSellers":
                this.equalDistributionPreSellers = element.meta_value;
              break;
            }
          }
          this.loadingEqual = false
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // AUTOMAÇÂO DE VENDEDOR -->
    filterOption(option) {
      if (option == "true") {
        this.option = true;
        this.final_optin = this.option;
      }
      if (option == "false") {
        (this.option = false), (this.final_optin = this.option);
      }
    },
    openModal() {
      this.render = true
      this.opt = { id: 2, name: 'Distribuição de leads' }
      this.formulario = [];
      this.sellersSelected2 = [];
      this.preSellersSelected2 = [];
      this.ignoreOthersDistributions = false;
    },
    openModal2() {
      // AUTOMAÇÂO DE VENDEDOR -->>
      this.getUserAutomation();
      // // DISTRIBUIÇÂO DE LEADS -->>
      this.fetchOptions();
      // DISTRIBUIÇÂO DE LEADS POR PORCEMTAGEM -->>
      this.fetchSellers();
      this.fetchOpts();

      this.fetchTagService()
      this.getVendedor()
    },
    getUserAutomation() {
      serviceSellerAutomation
        .read({ id: this.$store.getters.user?.user?.tenant_id ?? 0 })
        .then((resp) => {
          if (resp.msg == "Nenhuma automação encontrada") {
            return;
          }
          this.dia_seletec = this.returnDays(
            JSON.parse(resp.days_of_week) ?? []
          );
          this.hr_inicial = this.formatTime(resp.start_time);
          this.hr_final = this.formatTime(resp.end_time);
          this.tags_selected = resp.tags_in;
          this.tags_saida_selected = resp.tags_out;
          this.seller_selected = resp.users ?? [];
          this.tempo = resp.maxTime;
          this.option = resp.status;          
        })
        .finally(() => {
          this.loadingAuto = false;
        });
    },
    formatTime(time) {
      var time = time.toString().split("");
      return (parseInt(time[0] + time[1]) * 6) + Math.round(parseInt(time[2] + time[3]) / 10)
    },
    returnDays(days) {
      var filtered_days = [];
      days.forEach((day) => {
        var result = this.dias_semana.find((x) => x.id === parseInt(day));
        if (result) {
          filtered_days.push(result);
        }
      });
      return filtered_days;
    },
    filterDiasSemanas(valor) {
      var result = [];
      var data = valor;
      for (let i = 0; i < data.length; i++) {
        const element = data[i].id;
        result.push(element);
      }
      this.final_dia_selected = result;
    },
    filterTags(valor) {
      var result = [];
      var data = valor;
      for (let i = 0; i < data.length; i++) {
        const element = data[i].id;
        result.push(element.toString());
      }
      this.final_tag_selected = result;
    },
    filterTagsSaida(valor) {
      var result = [];
      var data = valor;
      for (let i = 0; i < data.length; i++) {
        const element = data[i].id;
        result.push(element.toString());
      }
      this.final_tag_saida_selected = result;
    },
    filterSeller(valor) {
      var result = [];
      var data = valor;
      for (let i = 0; i < data.length; i++) {
        const element = data[i].id;
        result.push(element.toString());
      }
      this.final_seller_selected = result;
    },
    onSubmit() {
      this.loading = true
      this.$validator.validateAll().then((result) => {
        if (result) {
          if (this.final_dia_selected.length === 0) {
            this.$grToast.toast("Escolha ao menos um dia da semana", {
              title: "Automação de vendedores",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
            this.loading = false
            return;
          }
          if (this.final_tag_selected.length === 0) {
            this.$grToast.toast("Escolha ao menos uma tag de entrega", {
              title: "Automação de vendedores",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
            this.loading = false
            return;
          }
          if (this.final_tag_saida_selected.length === 0) {
            this.$grToast.toast("Escolha ao menos uma tag de saída", {
              title: "Automação de vendedores",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
            this.loading = false
            return;
          }
          if (this.final_seller_selected.length === 0) {
            this.$grToast.toast("Escolha ao menos um vendedor", {
              title: "Automação de vendedores",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
            this.loading = false
            return;
          }
          if (this.tempo === 0) {
            this.$grToast.toast("Defina um tempo válido", {
              title: "Automação de vendedores",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
            this.loading = false
            return;
          }
          var data = {
            status: this.option,
            start_time: this.time1Label.replace(":", ""),
            end_time: this.time2Label.replace(":", ""),
            maxTime: this.tempo,
            days_of_week: this.final_dia_selected,
            tags_in: this.final_tag_selected,
            tags_out: this.final_tag_saida_selected,
            users: this.final_seller_selected,
          };
          serviceSellerAutomation         
            .create(data)
            .then((resp) => {             
              this.$grToast.toast("Automação salva com sucesso", {
                title: "Automação de vendedores",
                variant: "info",
                autoHideDelay: 5000,
                appendToast: true,
              });
            })
            .catch((err) => {
              this.loading = false
              this.$grToast.toast("Erro ao fazer automação", {
                title: "Automação de vendedores",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
            })
            .finally(() => {
              this.loading = false
              this.$bvModal.hide("Modal-Automacao");
            });
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.loading-all {
  position: absolute;
  width: calc(100% - 60px);
  height: calc(100% - 118px);
  z-index: 10;
  background-color: #fff9;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sub-card {
  padding: 15px;
  border: 1px solid var(--white-medium);
  border-radius: 10px;
}
.equal-seller {
  display: grid;
  grid-template-columns: 5fr 2fr 1fr;
  align-items: center;
  margin: 0 -15px;
  padding: 0 15px;
  &:nth-child(2n) {
    background-color: var(--greenn2);
  }
  p {
    margin: 0;
    font-size: 14px;
  }
  p:first-child {
    font-weight: 600;
  }
  input {
    margin: 10px 0;
  }
  img {
    margin: 0 auto;
    cursor: pointer;
  }
}
input[type="range"]::-webkit-slider-thumb {
  background: var(--greenn);
  position: relative;
  z-index: 5;
  margin-top: -6px;
}
input[type="range"]::-webkit-slider-runnable-track {
  height: 5px;
  background: var(--white-medium);
  border-radius: 3px;
}
.input-detail {
  height: 5px;
  background-color: var(--greenn);
  position: absolute;
  top: 22px;
  margin-left: 23px;
  div {
    position: relative;
    width: 100%;
    height: 100%;
    &::after {
      content: attr(label2);
      font-weight: 600;
      font-size: 12px;
      padding: 0px 6px;
      border-radius: 3px;
      color: white;
      background-color: var(--greenn);
      position: absolute;
      top: 12px;
      right: 0;
      transform: translateX(50%);
    }
    &::before {
      content: attr(label1);
      font-weight: 600;
      font-size: 12px;
      padding: 0px 6px;
      border-radius: 3px;
      color: white;
      background-color: var(--greenn);
      position: absolute;
      top: 12px;
      left: 0;
      transform: translateX(-50%);
    }
  }
}
.time-range {
  position: relative;
  width: 100%;
  height: 60px;
  input {
    position: absolute;
    width: 100%;
  }
}
.explain-card {
  color: var(--greenn);
  background-color: var(--greenn2);
  padding: 10px 15px;
  border: 1px solid var(--greenn);
  border-radius: 10px;
  width: 100%;
  font-size: 14px;
  margin-bottom: 10px;
}
.select-days {
  display: grid; 
  grid-template-columns: repeat(7, 1fr); 
  gap: 10px;
  > div {
    border-radius: 10px;
    padding: 5px;
    text-align: center;
    border: 1px solid var(--white-medium);
    font-size: 14px;
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      border-color: var(--greenn);
    }
  }
  .selected-day {
    border-color: var(--greenn);
    background-color: var(--greenn2);
    color: var(--greenn);
  }
}
.header {
  border-bottom: 1px solid #ededf0;
  padding-bottom: 20px;
}
/* DISTRIBUIÇÂO DE LEADS POR PORCENTAGEM*/
.container-automation-leads-percentage {
  padding-top: 20px;
  p {
    margin: 0;
    font-size: 14px;
    font-weight: normal;
    color: #81858e;
    line-height: 1.5;
  }

  p + p {
    margin-top: 5px;
  }

  .link-documentacao {
    padding: 0 25px !important;
    height: 40px !important;
    font-size: 12px;
  }

  .top-dados {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  @media screen and (max-width: 768px) {
    .top-dados {
      display: block;
    }

    .top-dados #limpar {
      margin-top: 15px;
    }
  }

  .form-relative {
    position: relative;
  }

  .btn-todos {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 14px;
    color: #81858e;
  }

  .seller_opt {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;

    input {
      margin-top: 16px;
    }
  }

  #remove {
    text-align: center;
  }

  #img-remove {
    padding-left: 90px;
    cursor: pointer;
  }

  .name {
    font-weight: 600;
    color: var(--gray01);
  }

  .email {
    font-weight: 400;
    color: var(--gray01);
  }

  p {
    word-break: break-all;
  }

  .cont {
    height: 460px;
    overflow-y: auto;
  }

  .cont::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 20px;
  }

  .cont::-webkit-scrollbar-track {
    background-color: transparent;
  }

  .cont::-webkit-scrollbar {
    width: 8px;
    background: transparent;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }
}
/* DISTRIBUIÇÂO DE LEADS*/
.container-automation-leads {
  padding-top: 20px;
  p {
    margin: 0;
    font-size: 14px;
    font-weight: normal;
    color: #81858e;
    line-height: 1.5;
  }

  p + p {
    margin-top: 5px;
  }

  .link-documentacao {
    padding: 0 25px !important;
    height: 40px !important;
    font-size: 12px;
  }

  .top-dados {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  @media screen and (max-width: 768px) {
    .top-dados {
      display: block;
    }

    .top-dados #limpar {
      margin-top: 15px;
    }
  }

  .form-relative {
    position: relative;
  }

  .btn-todos {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 14px;
    color: #81858e;
  }
}
/* AUTOMAÇÂO DE VENDEDOR */
.container-automation-seller {
  input {
    width: 100%;
  }
  .semana {
    display: flex;
    margin: 0 auto;
    gap: 20px;
  }
  .flex {
    display: flex;
    gap: 15px;
  }
  .display-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
  }
  .titulo {
    font-weight: 600;
    font-size: 14px;
  }
  .container-flex {
    display: flex;
    flex-direction: column;
  }
}
</style>

<style lang="scss">
#Modal-Automacao {
  nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    background-color: var(--greenn2);
    font-weight: 700;
  }
  a {
    font-weight: 500;
    color: var(--greenn);
  }
  .nav-pills .nav-link{
    padding: 10px 25px;
    border-radius: 10px;
  }
  .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
      color: #fff;
      background-color: var(--greenn2);
  }
  .tab-content {
    max-height: calc(100vh - 450px);
    height: calc(100vh - 450px);
    overflow-y: auto;
    overflow-x: hidden;
    padding: 20px 30px;
    margin-top: 15px;
    border-radius: 10px;
    border: 1px solid var(--white-medium);
  }
  .multiselect__tag{
    padding: 7.5px 35px 7.5px 10px !important;
  }
  .multiselect__tags{
    max-height: 136px;
  }
  .multiselect__tag-icon{
    background-color: rgba(247,247,247, 0.2) !important;
    border-radius: 100%;
    width: 20px;
    height: 20px;
    font-weight: 400;
    top: 4px;
    right: 5px;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .multiselect__tag-icon:after{
    color:var(--white-light) !important;
    font-size: 25px !important;
  }
}
</style>
